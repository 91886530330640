
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import type { GetStaticProps, NextPage } from 'next';
import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { VideoWeAreBevMaq } from '@/components/VideoWeAreBevMaq';
import { NextSeo } from 'next-seo';
import LinkTranslated from '@/components/Translator/LinkTranslated';
import { motion } from 'framer-motion';
import Link from 'next/link';

const Index: NextPage = function () {
  const { t } = useTranslation();
  const router = useRouter();
  return (
    <div className=''>
      <NextSeo
        title={t('seo:index.title')}
        description={t('seo:index.description')}
        openGraph={{
          url: process.env.SITE_URL_EN,
          title: t('seo:index.openGraph.title'),
          description: t('seo:index.description'),
        }}
        canonical={router.asPath}
      />
      <Header />
      <NowWithBevmaq />
      <Selling />
      <Buying />
      <Services />
      <VideoWeAreBevMaq />
    </div>
  );
};

function Header() {
  const { t } = useTranslation('common');
  return (
    <div className='relative flex min-h-[20em] w-full flex-col items-center justify-center space-y-8 bg-gray-200 pb-4'>
      <Image
        src={'/header.jpg'}
        alt='Buy and sell used machinery'
        layout='fill' // required
        objectFit='cover' // change to suit your needs
        className='opacity-25' // just an example
        priority={true}
      />
      <motion.h1
        className='z-10 text-center text-4xl font-medium text-gray-900'
        data-testid='header-title'
        initial={{ opacity: 0, y: +100 }}
        animate={{ opacity: 1, y: 0, transition: { duration: 0.5 } }}
      >
        {t('platform')}
      </motion.h1>
      <motion.div
        className='z-10 mx-6 mb-12 flex space-x-4 md:space-x-16'
        initial={{ opacity: 0, y: +100 }}
        animate={{
          opacity: 1,
          y: 0,
          transition: { duration: 0.5, delay: 0.3 },
        }}
      >
        <SellLink />
        <BuyLink />
      </motion.div>
    </div>
  );
}

function NowWithBevmaq() {
  const { t } = useTranslation('common');
  const img = t('Now With Bevmaq Image');

  return (
    <div className='flex w-full justify-center bg-slate-900 text-white lg:p-12'>
      <div className='grid max-w-7xl flex-grow gap-4 p-4 md:grid-cols-2'>
        <div className='md:p-2'>
          <h2 className='title mt-2 text-center md:text-left'>
            {t('Sell and Buy')}
          </h2>
          <h3 className='subtitle my-6'>{t('Sell and Buy body')}</h3>
          <div className='my-8 flex text-center md:text-left'>
            <DiscoverLink isMobileFullWidth={true} />
          </div>
        </div>
        <div className='relative flex h-96 w-full flex-col items-center justify-center space-y-8 lg:h-80'>
          <Link href='/now-with-bevmaq'>
            <a>
              <Image
                src={`/${img}`}
                alt='Now with Bevmaq'
                layout='fixed' // required
                objectFit='contain'
                className=''
                width={350}
                height={250}
              />
            </a>
          </Link>
        </div>
      </div>
    </div>
  );
}

function DiscoverLink({ isMobileFullWidth }: { isMobileFullWidth?: boolean }) {
  const { t } = useTranslation();
  return (
    <LinkTranslated
      href='/now-with-bevmaq'
      aProps={{
        className:
          'rounded px-8 py-4 text-white hover:bg-slate-800 text-center border border-2 border-orange-400 ' +
          (isMobileFullWidth ? ' w-full lg:w-auto' : ''),
      }}
    >
      {t('common:Discover')}
    </LinkTranslated>
  );
}

function Selling() {
  const { t } = useTranslation('common');
  return (
    <div className='flex w-full justify-center bg-white lg:p-12'>
      <div className='grid max-w-7xl flex-grow gap-4  p-4 md:grid-cols-2'>
        <div className='md:p-2'>
          <h2 className='title mt-2 text-center md:text-left'>
            {t('Selling Used Beverage Machines')}
          </h2>
          <h3 className='subtitle my-6'>
            {t('We offer a hassle-free transaction')}
          </h3>
          <div className='my-8 flex text-center md:text-left'>
            <SellLink isMobileFullWidth={true} />
          </div>
        </div>
        <div className='relative flex h-96 w-full flex-col items-center justify-center space-y-8 bg-gray-200 lg:h-80'>
          <Image
            src={'/machine.jpg'}
            alt='Buy and sell used machinery'
            layout='fill' // required
            objectFit='cover'
            className=''
          />
        </div>
      </div>
    </div>
  );
}

function SellLink({ isMobileFullWidth }: { isMobileFullWidth?: boolean }) {
  const { t } = useTranslation();
  return (
    <LinkTranslated
      href='/sell'
      aProps={{
        className:
          'rounded px-8 py-4 font-bold text-white bg-btn text-center' +
          (isMobileFullWidth ? ' w-full lg:w-auto' : ''),
      }}
    >
      {t('common:Sell Machines')}
    </LinkTranslated>
  );
}
function BuyLink({ isMobileFullWidth }: { isMobileFullWidth?: boolean }) {
  const { t } = useTranslation();
  return (
    <LinkTranslated
      href='/buy'
      aProps={{
        className:
          'rounded bg-slate-800 px-8 py-4 font-bold text-white hover:bg-slate-900 text-center ' +
          (isMobileFullWidth ? ' w-full lg:w-auto' : ''),
      }}
    >
      {t('common:Buy Machines')}
    </LinkTranslated>
  );
}

function Buying() {
  const { t } = useTranslation('common');
  return (
    <div className='flex w-full justify-center bg-gray2-100 lg:p-12'>
      <div className='grid max-w-7xl flex-grow gap-4  p-4 md:grid-cols-2'>
        <div className='lg:p-2'>
          <h2 className='title mt-4 text-center md:text-left'>
            {t('Buying Used Beverage Machines')}
          </h2>
          <h3 className='subtitle my-6'>
            {t('For our used beverage machines')}
          </h3>
          <div className='flex text-center md:text-left'>
            <BuyLink isMobileFullWidth={true} />
          </div>
        </div>
        <div className=''>
          <div className='relative flex h-80 w-full flex-col items-center justify-center space-y-8 bg-gray-200'>
            <Image
              src={'/machine2.jpg'}
              alt='Buying Used Beverage Machines'
              layout='fill' // required
              objectFit='cover'
              className=''
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function Services() {
  const { t } = useTranslation('common');
  return (
    <div className='flex w-full justify-center bg-slate-900 lg:p-12'>
      <div className=' max-w-7xl  gap-4  p-4'>
        <div className='lg:p-2'>
          <h2 className='title mt-4 text-center text-white'>
            {t('All-round Carefree Package')}
          </h2>
          <h3 className='subtitle my-6 text-center text-white'>
            {t('Services Subtitle')}
          </h3>
          <div className='flex justify-center'>
            <div className='grid grid-cols-2 gap-5'>
              <ServiceLink
                isMobileFullWidth={true}
                url='/services/data-driven-pricing'
                title={t('Data-driven Pricing')}
              />
              <ServiceLink
                isMobileFullWidth={true}
                url='/services/global-marketing'
                title={t('Global marketing')}
              />
              <ServiceLink
                isMobileFullWidth={true}
                url='/services/international-logistics'
                title={t('International Logistics')}
              />
              <ServiceLink
                isMobileFullWidth={true}
                url='/services/warehouse-service'
                title={t('Warehouse Service')}
              />
              <ServiceLink
                isMobileFullWidth={true}
                url='/services/project-consult'
                title={t('Project Consult')}
                className='col-span-2'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function ServiceLink({
  isMobileFullWidth,
  url,
  title,
  className,
}: {
  isMobileFullWidth?: boolean;
  url: string;
  title: string;
  className?: string;
}) {
  const { t } = useTranslation();
  return (
    <LinkTranslated
      href={url}
      aProps={{
        className:
          'rounded px-8 py-4 text-white hover:bg-slate-800 text-center border border-2 border-orange-400 ' +
          className +
          (isMobileFullWidth ? ' w-full lg:w-auto' : ''),
      }}
    >
      {t(title)}
    </LinkTranslated>
  );
}

 const _getStaticProps: GetStaticProps = async () => {
  return {
    props: {
      hasContainer: false,
    },
  };
};

export default Index;


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        let res = _getStaticProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  