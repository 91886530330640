export function YoutubeVideo({ url }: { url: string }) {
  const urlEmbed = !url.match('/embed')
    ? 'https://www.youtube.com/embed/' +
      url.replace('https://www.youtube.com/watch?v=', '')
    : url;

  return (
    <div className='aspect-h-9 aspect-w-16 '>
      <iframe
        className='yt_player_iframe'
        src={urlEmbed}
        loading='lazy'
        frameBorder='0'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
      />
    </div>
  );
}
