import useTranslation from 'next-translate/useTranslation';
import { YoutubeVideo } from './YoutubeVideo';

export function VideoWeAreBevMaq() {
  const { t } = useTranslation();
  return (
    <div className='mb-4 flex w-full flex-col bg-white px-2 py-4'>
      <div className='mx-auto max-w-7xl space-y-8'>
        <h3 className='text-center text-2xl font-semibold text-black'>
          {t('common:We are BEVMAQ - The Platform for Used Beverage Machinery')}
        </h3>
        <div className='mx-auto max-w-3xl'>
          <YoutubeVideo url={t('common:youtubeVideo')} />
        </div>
      </div>
    </div>
  );
}
